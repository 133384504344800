<template>
    <a-modal v-model:visible="visible" :title="title" width="768px" :footer="false">
        <a-form ref="form" :model="form" :rules="rules" :label-col="{ span: 4, offset: 1 }" :wrapper-col="{ span: 15 }">
            <a-form-item label="供应商名称" name="name">
                <a-input v-model:value="form.name" :disabled="!edit" />
            </a-form-item>
            <a-form-item label="地址" name="address">
                <a-input v-model:value="form.address" :disabled="!edit" />
            </a-form-item>
            <a-form-item label="图片" name="images">
                <ImageUpload v-model:value="form.images" :disabled="!edit" />
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 14, offset: 5 }">
                <a-button type="primary" @click="submit" v-if="edit" style="margin-right:20px">提交</a-button>
                <a-button @click="visible = false">关闭</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script>
/**
 * 就这个供应商弹窗写的和其他的不同，但是我懒得改了，效果其实是一样的
 * 弹窗标准写法参照物料：./modal/material，这个过时了
 */
import ImageUpload from '@/components/ImageUpload';
export default {
    name: 'SupplierModal',
    components: { ImageUpload },
    props: {
        supplier_id: {
            type: Number,
            required: true,
        },
        visible: {
            type: Boolean,
            required: true,
        },
        edit: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            title: '',
            other: '',
            form: {
                name: '',
                address: '',
                images: [],
            },
            rules: {
                name: [{ required: true, message: '请输入供应商名称', trigger: 'blur' }],
                address: [{ required: true, message: '请输入供应商地址', trigger: 'blur' }],
            },
        };
    },
    watch: {
        visible(value) {
            this.$emit('update:visible', value);
            value ? this.init() : '';
            return value;
        },
    },
    methods: {
        init() {
            if (this.edit && this.supplier_id == 0) {
                this.title = '新增供应商';
                this.form = {
                    name: '',
                    address: '',
                    images: [],
                };
                this.admin = false;
            } else {
                // 从服务器获取供应商数据
                this.$api('getSupplierDetail', { supplier_id: this.supplier_id }).then((res) => {
                    let supplier = res.supplier_detail;
                    this.title = `${this.edit ? '修改' : '查看'}供应商 【${supplier.name}】`;
                    this.form = {
                        name: supplier.name,
                        address: supplier.address,
                        images: JSON.parse(supplier.images),
                    };
                });
            }
        },
        submit() {
            this.$refs.form.validate().then(() => {
                let data = {
                    name: this.form.name,
                    address: this.form.address,
                    images: JSON.stringify(this.form.images),
                };
                if (this.supplier_id == 0) {
                    this.$api('addSupplier', data).then((res) => {
                        if (res.result == '操作成功') {
                            this.$message.success('数据已添加');
                            this.$emit('update:visible', false);
                            this.$emit('done');
                        }
                    });
                } else {
                    data.supplier_id = this.supplier_id;
                    this.$api('saveSupplier', data).then((res) => {
                        if (res.result == '操作成功') {
                            this.$message.success('修改已完成');
                            this.$emit('update:visible', false);
                            this.$emit('done');
                        }
                    });
                }
            });
        },
    },
};
</script>

<style></style>
