<template>
    <base-content>
        <template #button>
            <!-- <a-button type="primary" @click="getData" v-if="permit('view')"><SearchOutlined />查找</a-button> -->
            <a-button type="primary" @click="showModal(true, 0)" v-if="permit('add')"><PlusOutlined />添加</a-button>
        </template>
        <a-table class="table" :columns="columns" :data-source="data" rowKey="id" :pagination="false" bordered size="middle">
            <template #image="scope">
                <span>{{ JSON.parse(scope.record.images).length ? `共${JSON.parse(scope.record.images).length}张` : '无' }}</span>
            </template>
            <template #operation="scope">
                <a-button type="link" size="small" @click="showModal(false, scope.record.id)" v-if="permit('view')">查看</a-button>
                <a-button type="link" size="small" @click="showModal(true, scope.record.id)" v-if="permit('edit')" :disabled="!!scope.record.delete_flag">修改</a-button>
                <a-button type="link" size="small" @click="$refs.DataLogModal.show(scope.record.id)" v-if="permit('admin')">管理</a-button>
                <a-button type="link" size="small" @click="showModal(false, scope.record.id)" v-if="permit('delete')" :disabled="!!scope.record.delete_flag">删除</a-button>
            </template>
        </a-table>
        <a-pagination class="pagination" show-size-changer v-model:current="page" v-model:pageSize="perpage" :total="data_count" @change="pageChange" @showSizeChange="pageChange" :pageSizeOptions="['10', '15', '20', '50', '100']" />
        <SupplierModal v-model:visible="supplier_visible" :supplier_id="supplier_id" :edit="supplier_edit" @done="getData" />
        <DataLogModal ref="DataLogModal" data_table="supplier" />
    </base-content>
</template>

<script>
import { SearchOutlined, PlusOutlined } from '@ant-design/icons-vue';
import SupplierModal from '../components/modal/Supplier';
import DataLogModal from '../components/modal/DataLog';
export default {
    components: { SearchOutlined, PlusOutlined, SupplierModal, DataLogModal },
    data() {
        return {
            // 当前页面的权限
            permissions: [],
            // 控制供应商弹窗与数据日志弹窗的变量
            datalog_visible: false,
            supplier_visible: false,
            supplier_id: 0,
            supplier_edit: false,
            // 表格字段
            columns: [
                { title: 'ID', dataIndex: 'id', width: 50, align: 'center' },
                { title: '名称', dataIndex: 'name' },
                { title: '地址', dataIndex: 'address', ellipsis: true },
                { title: '图片数量', width: 120, align: 'center', slots: { customRender: 'image' } },
                { title: '操作', align: 'center', width: 200, slots: { customRender: 'operation' } },
            ],
            // 页码
            page: 1,
            // 每页数量
            perpage: 10,
            // 数据总数
            data_count: 0,
            // 当前表格内的数据
            data: [],
        };
    },
    methods: {
        permit(permission) {
            return this.permissions.indexOf(permission) != -1;
        },
        getDataCount() {
            this.$api('getSupplierCount').then((res) => {
                this.data_count = res.supplier_count;
            });
        },
        getData() {
            this.$api('getSupplierList', { page: this.page, perpage: this.perpage }).then((res) => {
                this.data = res.supplier_list;
            });
        },
        showModal(edit, supplier_id = 0) {
            this.supplier_id = supplier_id;
            this.supplier_edit = edit;
            this.supplier_visible = true;
        },
        showAdmin(supplier_id) {
            this.supplier_id = supplier_id;
            this.datalog_visible = true;
        },
        pageChange(page, size) {
            setTimeout(this.getData, 5);
        },
    },
    created() {
        this.getDataCount();
        this.getData();
        this.permissions = this.$store.getters.operationPermission('supplier');
    },
};
</script>

<style scoped>
.table {
    margin-bottom: 10px;
}
.pagination {
    display: flex;
    justify-content: center;
}
</style>
